/* You can add global styles to this file, and also import other style files */
@import "./assets/SCSS/variables";

// @import "./assets/SCSS/buttons";

@import "./assets/SCSS/customs";

@import "./assets/SCSS/typography";

@import "../node_modules/@fortawesome/fontawesome-free/css/all.css";

@import "../node_modules/ngx-bootstrap/datepicker/bs-datepicker.scss";

@import "~bootstrap/dist/css/bootstrap.css";

@import 'ngx-toastr/toastr';




// Datepicker

.theme-green .bs-datepicker-head {
    background-color:$dark-blue;
}

.bs-datepicker-body table td:not(.disabled):not(.week) span:not(.disabled):not(.is-other-month) {
    cursor: pointer;
    color: gray;

}

.bs-datepicker-head {
    color:$white;

}


.theme-green .bs-datepicker-body table td span.selected, .theme-green .bs-datepicker-body table td.selected span, .theme-green .bs-datepicker-body table td span[class*=select-]:after, .theme-green .bs-datepicker-body table td[class*=select-] span:after {
    background-color:$dark-blue;
}


// AgGrid
@import "../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";

.ag-theme-alpine {
  @include ag-theme-alpine();
  font-family: $font-family-display;
  color: $gray !important;
  overflow: auto;
}

.ag-grid-container {
    position: relative;
    z-index: 2;
  }


.ag-theme-alpine .ag-row-hover, .ag-theme-alpine .ag-list-item.ag-active-item {
  background-color: $gray-400   !important;
}


.ag-theme-alpine .ag-header{
    background-color: $dark-blue  !important;
    color: $white;
}

.ag-theme-alpine .ag-header-group-cell {
    font-weight: normal;
    font-size: 16px;
}

.ag-theme-alpine .ag-header-cell-text {
    font-size: 12px;
    color: $white;

}

.ag-theme-alpine .ag-row-selected {
  //background-color: $gray !important;
  border: none;
  padding: 0;
  background: transparent;

}

.ag-ltr, .ag-rtl {
  .ag-has-focus .ag-cell-focus:not(.ag-cell-range-selected),
  .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
  .ag-cell-range-single-cell.ag-cell-range-handle {
      border: none !important;
  }
}

.ag-center-cols-viewport {
  overflow-x: hidden;
}

.ag-row {
  cursor: pointer;
}

.ag-row-status-green {
  color: green;
}

.ag-row-selected > .ag-row-status {
  color: red !important;
}

h3 {
  font-size: 1.3rem;
}


html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
