/* Regular */
@font-face {
  font-family: 'Barlow';
  font-weight: normal;
  font-style: normal;
  src: url('../fonts/Barlow/Barlow-Regular.ttf') format('truetype');
}

/* Italic */
@font-face {
  font-family: 'Barlow';
  font-weight: normal;
  font-style: italic;
  src: url('../fonts/Barlow/Barlow-Italic.ttf') format('truetype');
}

/* Bold */
@font-face {
  font-family: 'Barlow';
  font-weight: bold;
  font-style: normal;
  src: url('../fonts/Barlow/Barlow-Bold.ttf') format('truetype');
}
