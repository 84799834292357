

// Color system

$white: #fff !default;
$gray-100: #f8f9fa; // #f8f9fa !default;
$gray-200: #f6f6f6; // #e9ecef !default;
$gray-300: #dadada; // #dee2e6 !default;
$gray-400: #e7e8ea !default;
$gray-500: #cecfd1; // #adb5bd !default;
$gray-600: #aab0b0;
$gray-700: #c9d1d4; // #495057 !default;
$gray-800: #96a7b1 !default;
$gray-900: #5a7882; // #212529 !default;
$gray: #626a6d; // #212529 !default;
$black: #000 !default;


$light-blue: #e9edf9 !default;
$dark-blue: #2E628F !default;
$blue-500: #a3dcfa !default;
$blue-300: #d7ecfd !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #F9420C !default;
$orange: #f27e00; //#fd7e14 !default;
$yellow: #ffcb01 !default;
$yellow-500: #fee07d !default;
$yellow-300: #fdefc8 !default;
$green: #93c21a !default;
$green-500: #c3d984 !default;
$green-300: #e5ebc9 !default;
$teal: #20c997 !default;
$cyan: #3DF3F4 !default;
$dark: #626a6d !default;
$toastPrimary: #e9f3d1;


$colors: () !default;
$colors: map-merge(

    (
      'blue': $dark-blue,
      'indigo': $indigo,
      'purple': $purple,
      'pink': $pink,
      'red': $red,
      'orange': $orange,
      'yellow': $yellow,
      'green': $green,
      'teal': $teal,
      'cyan': $cyan,
      'white': $white,
      'gray': $gray-600,
      'gray-dark': $gray-800
    ),
    $colors
);

$primary: $dark-blue; //$dark-blue !default;
$secondary: $gray; //$gray !default;
$success: $green !default;
$info: $blue-500 !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $gray-800 !default;

$theme-colors: () !default;
$theme-colors: map-merge(

    (
      'primary': $primary,
      'secondary': $secondary,
      'success': $success,
      'info': $info,
      'warning': $warning,
      'danger': $danger,
      'light': $light,
      'dark': $dark
    ),
    $theme-colors
);

$font-family-display: 'Barlow';
$transition-base: all 0.4s ease-in-out;

//sidebar Component

$sidebar-width: 60px;
$sidebar-expanded-width: 13rem;
$header-height: 60px;


// All Components
.container {
    display: flex;
    font-family: $font-family-display;
    margin-left: 2.8rem;
    margin-top: -2rem;
  }

  h1,h2,h3{
    letter-spacing: 0.4px;
    color: $gray;
    font-family: $font-family-display;
  }

  h4,h5,h6{
    letter-spacing: 0.4px;
    color: $gray;
    font-size: 1rem;
    font-family: $font-family-display;
    font-weight: 600;
  }

  span,p{
    letter-spacing: 0.4px;

    font-size: 1rem;
    font-family: $font-family-display;
  }



  .content {
    flex: 1;
    padding: 1rem;
  }


// Dashboard Component

.dashboard-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
  }

  .card {
    background-color: $white;
    border: 1px solid #ccc;
    padding: 1rem;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    transition: transform 0.2s;

    h2 {
      margin-bottom: 0.5rem;
    }
  }


//details component

.custom-panel {
  margin-left: 1.5rem;
}

//
//.panel{
//  margin-left: 1.5rem;
//  font-size: 1.2rem;
//}

.header-container{
  float: left;
  font-family: $font-family-display;
  font-size: 1.1rem;
  font-weight: 800;
  color: $gray;
}

.icon-container {
  float: right;
}

.card-title{
  display: flex;
  float: left;
}

.custom-heading {
  font-weight: 600;
}

accordion-group {

  .card{
    border-radius: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;

  }

  ::ng-deep {
    div {
      &>div.panel-heading.card-header.panel-enabled {
       background-color:#ffffff;
       //box-shadow: 0px 3px 3px -1px rgba(71, 78, 106, 0.5);
       border-bottom:0;
        height: 2rem;
        .btn-link {
            color: $gray;

            text-transform: capitalize;
        }
      }
      &>div.panel-collapse.collapse.in.show>div {
        background-color:#ffffff;
        //box-shadow: -3px 6px 3px -3px rgba(71, 78, 106, 0.5);
        //margin-top: -1.5rem;
        border-bottom: 0px;
      }
    }
  }


}

i{
  color: $gray;
}

.inline {
  display: flex;
  flex-direction: column;

  .item {
      display: inline-block;
      margin-bottom: 0.1rem;


  }
}
